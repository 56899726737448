.sidebar {
    background-color: $card-bg;
    .navbar-brand {
        .logo-title {
            color: $white;
        }
    }
    .sidebar-header {
        border-color: $border-color;
    }
    .logo-main{
        .logo-normal.dark-normal{
            min-height: 30px;
            height: 30px;
            opacity: 1;
            transform: translateX(-120px);
            transition: all 0.3s ease-in-out;
        }
        .logo-mini.dark-mini{
            opacity: 0;
            min-height: 30px;
            height: 30px;
            transition: all 0.3s ease-in-out;
        }
    }
    .logo-main{
        .logo-mini{
            min-height: 30px;
            height: 30px;
            transition: all 0.3s ease-in-out;
            opacity: 0;
        }
        .logo-normal{
            min-height: 30px;
            height: 30px;
            opacity: 0;
            transition: all 0.3s ease-in-out;
        }
    }
    &.sidebar-mini{
        .dark-mini.logo-mini {
            min-height: 30px;
            height: 30px;
            opacity: 1;
            transform: translateX(-110px);
            transition: all 0.3s ease-in-out;
        }
        .dark-normal.logo-normal {
            min-height: 30px;
            height: 30px;
            opacity: 0;
            transition: all 0.3s ease-in-out;
        }
    }
}
.sidebar-list{
    .navbar-nav{
        .nav-item{
            .nav-link.static-item{
                .default-icon{
                    color: $light;
                }
            }
        }
    }
}

.sidebar.sidebar-color.sidebar-default {
	.nav-link.active {
		 color: var(--#{$variable-prefix}primary);
		background-color: $white;
	}
	.nav-link[aria-expanded=true] {
		 color: var(--#{$variable-prefix}primary);
		background-color: $white;
	}
}
.sidebar-base {
	.nav-item {
		.nav-link {
			&:not(.disabled) {
				color:$body-color;
			}
		}
	}
}


